<template>
  <div class="plant-card cursor-pointer">
    <b-card class="overflow-hidden mb-25" no-body>
      <b-row class="plant-card-height" no-gutters @click="plantCardClick">
        <b-col :class="imageClass" cols="3" style="background-color: var(--secondary-light)">
          <b-card-img
              :alt="imageDescription"
              :class="imageClass"
              :src="getImage"
              left/>
          <b-badge
              v-if="quantity > 1"
              class="badge-up mt-1 mr-1"
              variant="primary">
            {{ this.quantity }}
          </b-badge>
        </b-col>
        <b-col class="d-flex align-items-center pl-1 pr-50 plant-card-height" cols="8">
          <b-card-body class="p-0 pr-1 text-truncate">
            <b-card-text class="m-0">
              <h4 class="m-0 font-weight-bolder text-truncate" v-html="title === '' ? 'N/A' : title"/>
              <div class="text-truncate">{{ subtitle }}</div>
            </b-card-text>
            <div class="d-flex align-items-center">
              <img v-if="plantNotifications.water" alt="Icon Arrosage" class="plant-card-icon" src="@/assets/images/icons/tools/watering_can.svg">
              <img v-if="plantNotifications.sow" alt="Icon Semis" class="plant-card-icon" src="@/assets/images/icons/other/sowing_hand.svg">
              <img v-if="plantNotifications.transplant" alt="Icon repuiquer" class="plant-card-icon" src="@/assets/images/icons/tools/garden_trowel_dark.svg">
              <img v-if="plantNotifications.trim" alt="Icon Taille" class="plant-card-icon" src="@/assets/images/icons/tools/shear.svg">
              <img v-if="plantNotifications.harvest" alt="Icon Récolte" class="plant-card-icon" src="@/assets/images/icons/tools/basket.svg">
              <img v-if="plantNotifications.flower" alt="Icon Plante" class="plant-card-icon" src="@/assets/images/icons/plants/default_flower.svg">
            </div>
          </b-card-body>
          <slot></slot>
        </b-col>
        <b-col class="d-flex flex-column align-items-center justify-content-center pr-50 plant-card-height" cols="1">
          <img v-if="plantNotifications.vgarden" alt="Icône dans potager" class="my-25 plant-card-icon" src="@/assets/images/icons/tools/garden_trowel.svg">
          <img v-if="plantNotifications.local" alt="Icône plante locale" class="my-25 plant-card-icon" src="@/assets/images/icons/other/local.svg">
          <img v-if="plantNotifications.invasive" alt="Icône plante envahissante" class="my-25 plant-card-icon" src="@/assets/images/icons/other/invasive.svg">
          <PlantScore
              v-if="vGarden"
              :plantNotInVGarden="plantNotInVGarden"
              :received_interactions="received_interactions"
              :vGarden="vGarden"
              class="my-25 plant-card-icon"/>
        </b-col>
      </b-row>

      <b-card-footer v-if="vgardenId >= 0 && showTasks" class="bg-primary-light">
        <b-row>
          <b-col>
            <h3 class="text-primary"> Mes actions </h3>
          </b-col>
        </b-row>
        <PlantTasks :plantId="plantId" :vGardenPlantId="vGardenPlantId" @removed="$emit('plant-update')" @task-validated="$emit('plant-update')"/>

        <div class="d-flex justify-content-center mt-1">
          <b-button
              size="small"
              variant="primary"
              @click="openMoreInfo">
            <feather-icon
                class="mr-50"
                icon="ExternalLinkIcon"
                size="16"/>
            <span class="align-middle">Ouvrir la fiche plante</span>
          </b-button>
        </div>
      </b-card-footer>
    </b-card>

    <JayaPopUp ref="plantInfoPopUp" name="FichePlante">
      <PlantInfo
          :gardenPlantId="gardenPlantId"
          :isInGarden="gardenPlant"
          :plantId="plantIdMoreInfos"
          :quantity="quantity"
          :title="title"
          @close-delete="stopPopUp"
          @plant-deleted="transmitUpdate"/>
    </JayaPopUp>
  </div>
</template>

<script type="text/javascript">
import {BBadge, BButton, BCard, BCardBody, BCardFooter, BCardImg, BCardText, BCol, BModal, BRow,} from "bootstrap-vue";
import JayaPopUp from "@/components/global/JayaPopUp.vue"
import PlantScore from "@/components/plants/PlantScore.vue"
import PlantInfo from "@/components/plants/PlantInfo.vue"
import PlantTasks from '@/components/vgarden/tasks/VGardenPlantTasks.vue'

export default {
  name: "plant-card",
  components: {
    name,
    BButton,
    BCard,
    BCardBody,
    BCardImg,
    BCardText,
    BCardFooter,
    BRow,
    BCol,
    BBadge,
    BModal,
    JayaPopUp,
    PlantInfo,
    PlantScore,
    PlantTasks,
  },
  props: {
    title: {
      type: String,
      default: "N/A"
    },
    subtitle: {
      type: String,
      default: "N/A"
    },
    plantId: {
      type: Number,
      default: -1
    },
    gardenPlant: {
      type: Boolean,
      default: false
    },
    gardenPlantId: {
      type: Number,
      default: -1
    },
    plantNotifications: {
      type: Object,
      default() {
        return {}
      }
    },
    clickToGiveId: {
      type: Boolean,
      default: false
    },
    quantity: {
      type: Number,
      default: -1
    },
    vGarden: {
      type: Object,
      default: null
    },
    vGardenPlantId: {
      type: Number,
      default: -1
    },
    plantPhotos: {
      type: Array,
      default: function () {
        return []
      }
    },
    plantPictos: {
      type: Array,
      default: function () {
        return []
      }
    },
    firstPlant: {
      type: Boolean,
      default: false
    },
    received_interactions: {
      type: Array,
      default: null
    },
    plantNotInVGarden: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      plantIdMoreInfos: null,
      showTasks: false,
      imageClass: "jaya-card-image"
    }
  },
  computed: {
    getImage() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.imageClass = "picto"
      if (this.plantPhotos.length > 0) {
        this.imageClass = "jaya-card-image"
        return process.env.VUE_APP_BACKEND_URL + '/..' + this.plantPhotos[0].photo_url + "&size=200"
      }
      if (this.plantPictos.length > 0) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        return require("@/assets/images/icons/plants/" + this.plantPictos[0]["name"])
      }
      return require("@/assets/images/icons/plants/default_leaf.svg")
    },
    imageDescription() {
      return "Image de " + this.title
    },
    vgardenId() {
      return this.vGarden ? this.vGarden.id : -1
    }
  },
  methods: {
    plantCardClick() {
      if (this.clickToGiveId) {
        this.$emit('cardClicked', this.plantId)
      } else if (this.vgardenId >= 0) {
        this.showTasks = !this.showTasks
      } else {
        this.openMoreInfo()
      }
    },
    openMoreInfo() {
      this.plantIdMoreInfos = this.plantId
      this.$refs.plantInfoPopUp.openPopUp()
      this.popUpMoreInfo = true
    },
    stopPopUp(event) {
      if (event) {
        this.popUpMoreInfo = false
      }
    },
    transmitUpdate() {
      this.$emit("plant-update")
    },
  }
}
</script>

<style lang="scss">
.plant-card {
  position: relative;
  width: 100%;
  margin: auto;
  max-width: 40rem;

  .plant-card-height {
    height: 84px;
    @media screen and (min-width: 1200px) {
      height: 112px;
    }
  }

  .plant-score-text {
    text-shadow: 1px 2px 2px black;
  }

  .picto {
    height: 84px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (min-width: 1200px) {
      height: 112px;
    }

    .picto {
      height: 70px;
      @media screen and (min-width: 1200px) {
        height: 90px;
      }
    }
  }

  .plant-card-icon {
    height: 25px;
    width: auto;
    @media screen and (min-width: 1200px) {
      height: 35px;
    }
  }
}
</style>
