<template>
  <div ref="plantSearch" class="plant-search">
    <SearchBar v-if="!getAdvice && (forceFilterPopup || $store.state.app.windowWidth < 768)" :filter="activeFilter" @filter-clicked="$refs.popUpFilters.toggle()" @update-search="search" id="searchInputParent"/>
    <SearchBar v-if="!getAdvice && $store.state.app.windowWidth >= 768 && !forceFilterPopup" @update-search="search" id="searchInputParent"/>
    <b-container fluid>
      <b-row v-if="listType==='cards'">
        <b-col v-if="activeFilter && !forceFilterPopup && $store.state.app.windowWidth >= 768" cols="4">
          <div class="encyclopedia-page-content w-full">
            <PlantFilter @filter-validated="setFilters" id="searchFilterParent"/>
          </div>
        </b-col>
        <b-col :cols="activeFilter && !forceFilterPopup && $store.state.app.windowWidth >= 768 ? 8 : 12" class="w-100 p-0">
          <div class="encyclopedia-page-content">
            <transition-group class="incard" name="list">
              <PlantCard
                  v-for="plant in plantList.results"
                  :key="plant.id"
                  :clickToGiveId="clickToGiveId"
                  :plant-photos="plant.photos"
                  :plant-pictos="plant.pictos"
                  :plantId="plant.id"
                  :plantNotifications="{local: plant.local, invasive: plant.invasive}"
                  :received_interactions="plant.received_interactions"
                  :subtitle="plant.scientific_name"
                  :title="plant.common_name"
                  :vGarden="vGarden"
                  plantNotInVGarden
                  :id="plantList.length >= 4 && plantList.results[3].id===plant.id ? 'fourthPlant' : null"
                  v-on:cardClicked="emitSelection(plant)"/>
            </transition-group>
            <div v-if="plantList.next">
              <b-spinner
                  style="width: 2rem !important; height: 2rem !important;"
                  variant="primary"
              ></b-spinner>
            </div>
            <div v-if="!plantList.next && searched">
              <b-card>
               Vous ne trouvez pas la plante que vous cherchez ?
                <span class="cursor-pointer text-primary"
                      @click="$router.push({name: 'user-feedbacks', query: {search: searchText, type:2}})">
                  Dites le nous ici !
                  <feather-icon
                    icon='LinkIcon'
                    size='24'/>
                </span>
              </b-card>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <div v-if="listType==='simple'" :style="'height: ' + activeSize + ';'" class="encyclopedia-page-content overflow-y-scroll">
      <b-list-group class="incard" flush>
        <b-list-group-item
            v-for="plant in plantList.results"
            :key="plant.id">
          <div :class="plantClass(plant.id)" class="plant-item cursor-pointer" @click="emitSelection(plant)">
            <strong>
              {{ plant.common_name }}
            </strong>
            <span>
            {{ plant.scientific_name }}
          </span>
          </div>
        </b-list-group-item>
      </b-list-group>
    </div>

    <b-modal ref="popUpFilters" class="holamundo" hide-footer scrollable title="Filtres plantes">
      <PlantFilter @filter-validated="setFilters"></PlantFilter>
    </b-modal>
    <EncyclopediaOnboarding v-if="displayOnboarding && canDisplayOnBoarding"/>
  </div>
</template>

<script>
import SearchBar from "@/components/global/SearchBar.vue"
import PlantCard from "@/components/plants/PlantCard.vue"
import PlantFilter from "@/components/plants/PlantFilter.vue"
import {BCol, BContainer, BListGroup, BListGroupItem, BModal, BRow, BSpinner, BCard} from "bootstrap-vue";
import EncyclopediaOnboarding from "@/components/onboarding/EncyclopediaOnboarding.vue";

export default {
  name: 'plant-search',
  components: {
    EncyclopediaOnboarding,
    SearchBar,
    PlantCard,
    PlantFilter,
    BRow,
    BCard,
    BCol,
    BContainer,
    BModal,
    BListGroup,
    BListGroupItem,
    BSpinner
  },
  data() {
    return {
      searchOffset: 0,
      waitScroll: false,
      searchText: '',
      plantList: [],
      filters: null,
      activeSize: '0px',
      selectedPlant: null,
      containerElement: null,//for scrolling purposes
      searched: false,
      displayOnboarding: false
    }
  },
  props: {
    size: {
      type: String,
      default: 'calc(100vh - 210px)'
    },
    listType: {
      type: String,
      default: 'cards'
    },
    searchLimit: {
      type: Number,
      default: 20
    },
    preloadContent: {
      type: Boolean,
      default: true
    },
    activeFilter: {
      type: Boolean,
      default: true
    },
    forceFilterPopup: {
      type: Boolean,
      default: false
    },
    clickToGiveId: {
      type: Boolean,
      default: false
    },
    vGarden: {
      type: Object,
      default: null
    },
    getAdvice: {
      type: Boolean,
      default: false
    },
    selectedPlantId: {
      type: Number,
      default: null
    },
    localAuth: {
      type: Number,
      default: null
    },
    vGPlantOnly: {
      type: Boolean,
      default: null
    },
    canDisplayOnBoarding: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.setCorrectParentForScrolling()
    if (this.preloadContent) {
      this.activeSize = this.size
      if (this.localAuth) {
        this.$store.dispatch("localAuthority/searchInvasives", {
          id: this.localAuth,
          limit: 100,
        })
            .then(response => {
              this.plantList = response.data
              // Update the width when the plants are set, make sure the update is
              // processed by adding a timeout.
              if (document.getElementById("swiper")) {
                setTimeout(() => document.getElementById("swiper").swiper.resize.resizeHandler())
              }
            })
            .catch(() => {
              this.plantList = []
            })
      } else if (this.getAdvice && this.vGarden && this.vGarden.id) {
        this.$store.dispatch("garden/getVGPlantsAdvice", {index: this.vGarden.id})
            .then((response) => {
              this.plantList = response.data
            })
            .catch(() => {
              this.plantList = []
            })
      } else {
        let payload = {
          limit: this.searchLimit,
          vgarden: this.vGarden ? this.vGarden.id : null,
          inter: this.vGarden ? true : null,
          vgplant_only: this.vGarden || this.vGPlantOnly ? true : null,
        }
        if (this.filters) {
          payload = {...payload, ...this.filters}
        }
        this.$store.dispatch("plant/search", payload)
            .then(response => {
              this.plantList = response.data
              this.displayOnboarding = true
              // Update the width when the plants are set, make sure the update is
              // processed by adding a timeout.
              if (document.getElementById("swiper")) {
                setTimeout(() => document.getElementById("swiper").swiper.resize.resizeHandler())
              }
            })
            .catch(() => {
              this.plantList = []
            })
      }
    }
    if (this.selectedPlantId !== null) {
      this.selectedPlant = this.selectedPlantId
    }
  },
  destroyed() {
    if (this.containerElement) {
      this.containerElement.removeEventListener('scroll', this.selfScroll)
    }
  },
  methods: {
    search(searchText) {
      this.searched = true
      this.activeSize = this.size
      this.searchText = searchText
      this.searchOffset = 0
      let payload = {
        search: this.searchText,
        limit: this.searchLimit,
        vgarden: this.vGarden ? this.vGarden.id : null,
        inter: this.vGarden ? true : null,
        vgplant_only: this.vGarden || this.vGPlantOnly ? true : null,
      }
      if (this.filters) {
        payload = {...payload, ...this.filters}
      }
      if (this.localAuth) {
        payload.id = this.localAuth
        this.$store.dispatch("localAuthority/searchInvasives", payload)
            .then(response => {
              this.plantList = response.data
            })
            .catch(() => {
              this.plantList = []
            })
      } else if (this.getAdvice && this.vGarden && this.vGarden.id) {
        this.$store.dispatch("garden/getVGPlantsAdvice", {index: this.vGarden.id})
            .then((response) => {
              this.plantList = response.data
            })
            .catch(() => {
              this.plantList = []
            })
      } else {
        this.$store.dispatch("plant/search", payload)
            .then(response => {
              this.plantList = response.data
            })
            .catch(() => {
              this.plantList = []
            })
      }

    },
    setCorrectParentForScrolling() {
      if (this.$refs.plantSearch
          && this.$refs.plantSearch.parentElement) {
        if (this.$refs.plantSearch.parentElement.id === "plantSearchTab") {
          this.containerElement = this.$refs.plantSearch.parentElement
          this.containerElement.addEventListener('scroll', this.selfScroll)
        } else if (this.$refs.plantSearch.parentElement.parentElement
            && this.$refs.plantSearch.parentElement.parentElement.id === "content") {
          this.containerElement = this.$refs.plantSearch.parentElement.parentElement
          this.containerElement.addEventListener('scroll', this.selfScroll)
        }
      }
    },
    scroll(event) {
      // If we are at the bottom of the scroll bar during scrolling, load the next plants
      if (this.plantList.next && document.body.scrollHeight - window.scrollY - window.innerHeight < 50) {
        this.currentScroll = event.target.scrollTop
        this.loadNext()
      }
    },
    selfScroll(event) {
      if ((event.target.scrollHeight - (event.target.scrollTop + event.target.clientHeight)) < 50) {
        this.loadNext()
      }
    },
    loadNext() {
      if (!this.waitScroll) {
        this.waitScroll = true
        let payload = {
          search: this.searchText,
          limit: this.searchLimit,
          offset: this.searchOffset + this.searchLimit,
          vgarden: this.vGarden ? this.vGarden.id : null,
          inter: this.vGarden ? true : null,
          vgplant_only: this.vGarden || this.vGPlantOnly ? true : null
        }
        if (this.filters) {
          payload = {...payload, ...this.filters}
        }

        this.$store.dispatch("plant/search", payload)
            .then(response => {
              this.plantList.next = response.data.next;
              this.searchOffset = this.searchOffset + this.searchLimit
              this.plantList.results = this.plantList.results.concat(response.data.results);
              this.waitScroll = false
            }).catch(() => {
          this.waitScroll = false
        });
      }

    },
    setFilters(event) {
      let filters = {}
      for (let item in event.filters) {
        if (event.filters[item].length) {
          filters[item] = event.filters[item]
        }
      }
      this.filters = filters
      this.$store.dispatch("plant/search", {...{search: this.searchText}, ...{limit: this.searchLimit,}, ...{vgarden: this.vGarden ? this.vGarden.id : null}, ...{vgplant_only: this.vGarden || this.vGPlantOnly ? true : null}, ...{inter: this.vGarden ? true : null}, ...filters})
          .then(response => {
            this.plantList = response.data
            if (event.close) {
              this.$refs.popUpFilters.hide()
            }
          })
          .catch(() => {
            this.plantList = []
          })
    },
    emitSelection(selectedPlant) {
      this.selectedPlant = selectedPlant.id
      this.$emit('newSelection', selectedPlant)
    }
  },
  computed: {
    plantClass() {
      var vm = this
      return function (id) {
        if (vm.selectedPlant == id) {
          return "green-plant"
        }
        return ""
      }
    }
  }
}
</script>

<style lang="scss">
.list-enter-active, .list-leave-active {
  transition: all 1s;
}

.plant-search {
  width: 100%;

  .encyclopedia-page-content {
    display: flex;
    flex-flow: column;
    align-items: center;
  }

  .incard {
    width: 100%;
  }

  .plant-item:active, .plant-item:hover {
    color: rgba(var(--vs-primary), 1);
    background: rgba(var(--vs-primary), 0.2);
    border-radius: 10px;
  }

  .green-plant {
    color: rgba(var(--vs-warning), 1);
    background: rgba(var(--vs-warning), 0.2);
    border-radius: 10px;
  }
}
</style>
