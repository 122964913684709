<template>
  <validation-observer v-slot="{invalid}">
    <div class="plant-filter w-100 mt-1">
      <b-card class="mb-0 p-1 pb-50" noBody>
        <b-form-group class="mb-0">
          <b-form-checkbox
              v-if="false"
              v-model="local"
              class="custom-control-primary">
            Plantes indigènes
          </b-form-checkbox>

          <b-form-checkbox
              v-model="balcony"
              class="custom-control-primary">
            Plantes pour balcon
          </b-form-checkbox>
        </b-form-group>
      </b-card>
      <app-collapse type="margin">
        <app-collapse-item title="Catégorie">
          <div slot="header">
            Catégorie
          </div>
          <div>
            <ul>
              <li v-for="item in plantCategories" v-bind:key="item.id">
                <b-form-checkbox v-model="filters.category" :value="item" class="w-full">{{ item }}</b-form-checkbox>
              </li>
            </ul>
          </div>
        </app-collapse-item>
        <app-collapse-item title="Biodiversité">
          <div slot="header">
            Biodiversité
          </div>
          <div>
            <ul>
              <li v-for="item in biodiversity" v-bind:key="item.id">
                <b-form-checkbox v-model="filters.concernedSpicie" :value="item[0].toUpperCase()+item.slice(1)"
                    class="w-full">
                  {{ item }}
                </b-form-checkbox>
              </li>
            </ul>
          </div>
        </app-collapse-item>
        <app-collapse-item title="Expositions">
          <div slot="header">
            Expositions
          </div>
          <div>
            <ul>
              <li v-for="item in expositions" v-bind:key="item.id">
                <b-form-checkbox v-model="filters.shade" :value="item" class="w-full">{{ item }}</b-form-checkbox>
              </li>
            </ul>
          </div>
        </app-collapse-item>
        <app-collapse-item title="Temporalité">
          <div slot="header">
            Temporalité
          </div>
          <div>
            <ul>
              <li v-for="item in durations" v-bind:key="item.id">
                <b-form-checkbox v-model="filters.duration" :value="item" class="w-full">{{ item }}</b-form-checkbox>
              </li>
            </ul>
          </div>
        </app-collapse-item>
        <app-collapse-item title="Besoin en matière organique">
          <div slot="header">
            Besoin en matière organique
          </div>
          <div>
            <ul>
              <li v-for="item in fertilities" v-bind:key="item.id">
                <b-form-checkbox v-model="filters.fertility" :value="item[0].toUpperCase()+item.slice(1)"
                    class="w-full">
                  <!--                {{item}}[0].toUpperCase()+item.slice(1)}}-->
                  {{ item }}
                </b-form-checkbox>
              </li>
            </ul>
          </div>
        </app-collapse-item>
        <app-collapse-item title="Couleur des fleurs">
          <div slot="header">
            Couleur des fleurs
          </div>
          <div>
            <ul>
              <li v-for="item in colors" v-bind:key="item.id">
                <b-form-checkbox v-model="filters.flowerColor" :value="item[0].toUpperCase()+item.slice(1)"
                    class="w-full">
                  {{ item }}
                </b-form-checkbox>
              </li>
            </ul>
          </div>
        </app-collapse-item>
        <app-collapse-item title="Température minimum">
          <div slot="header">
            Température minimum
          </div>
          <validation-provider v-slot="{errors}" name="Température" rules="integer|min_value:-50|max_value:50">
            <b-input-group>
              <b-form-input
                  v-model="temperature"
                  max="50"
                  min="-50"
                  step="1"
                  type="number"/>
              <b-input-group-append is-text>
                °C
              </b-input-group-append>
            </b-input-group>
            <b-form-text class="text-danger font-small-3">{{ errors[0] }}</b-form-text>
          </validation-provider>
        </app-collapse-item>
      </app-collapse>

      <b-row class="w-100 justify-content-around wrap">
        <b-col class="flex-centered mt-1" cols="auto" md="auto">
          <b-button class="float-left" variant="outline-primary" @click="removeAllFilters">Tout retirer
          </b-button>
        </b-col>
        <b-col class="flex-centered mt-1" cols="auto" md="auto">
          <b-button :disabled="invalid" class="float-right" variant="primary" @click="filterValidated">Filtrer
          </b-button>
        </b-col>
      </b-row>
    </div>
  </validation-observer>
</template>

<script type="text/javascript">
import {mapState} from "vuex";
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import {
  BButton,
  BCard,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormText,
  BInputGroup,
  BInputGroupAppend,
  BRow,
} from "bootstrap-vue";
import {extend, ValidationObserver, ValidationProvider} from 'vee-validate'
import {integer, max_value, min_value} from "vee-validate/dist/rules";

extend('max_value', {...max_value, message: "La température doit être inférieure à {max}°C"});
extend('min_value', {...min_value, message: "La température doit être supérieure à {min}°C"});
extend('integer', {...integer, message: "La température doit être un nombre entier !"});

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
    ValidationObserver,
    ValidationProvider,
    BButton,
    BCol,
    BFormCheckbox,
    BRow,
    BFormInput,
    BFormText,
    BInputGroupAppend,
    BInputGroup,
    BFormGroup, BCard
  },
  mounted() {
    this.filters = this.$store.state.plant.filters
    this.balcony = this.$store.state.plant.balcony
    this.local = this.$store.state.plant.local
    this.temperature = this.$store.state.plant.temperature
  },
  data() {
    return {
      filters: {
        category: [],
        shade: [],
        duration: [],
        fertility: [],
        beneficEffect: [],
        concernedSpicie: [],
        flowerColor: []
      },
      plantCategories: ['Arbre', 'Arbuste', 'Grimpante', 'Herbacée', 'Graminée', 'Lichen', 'Autre'],
      expositions: ['Soleil', 'Mi-ombre', 'Ombre'],
      durations: ['Annuelle', 'Bisannuelle', 'Vivace'],
      fertilities: ['Faible', 'Moyen', 'Fort'],
      colors: ['Jaune', 'Blanc', 'Rouge', 'Vert', 'Violet', 'Bleu', 'Marron', 'Orange'],
      biodiversity: ['Oiseaux', 'Insectes', 'Mammifères', 'Lézards', 'Crapauds', 'Champignons', 'Araignées', 'Rhizobium'],
      temperature: 0,
      temperature_changed: false,
      local: false,
      balcony: false  ,
      noPhoto: false
    }

  },
  methods: {
    filterValidated() {
      let filterEvent = {}
      filterEvent.filters = this.filters
      if (this.temperature_changed) {
        filterEvent.filters.temperature = [this.temperature]
      }
      if (this.local) {
        filterEvent.filters.local_auth = this.$store.state.garden.gardens.find(gd => {
          return gd.is_active
        }).local_authorities
      }
      filterEvent.filters.balcony = this.balcony ? [this.balcony] : []
      if (this.noPhoto) {
        filterEvent.filters.nophoto = "true"
      } else {
        delete filterEvent.filters.nophoto
      }
      filterEvent.close = true
      this.$store.commit('plant/UPDATE_FILTERS', {
        filters: this.filters,
        balcony: this.balcony,
        temperature: this.temperature,
        local: this.local
      })
      this.$emit('filter-validated', filterEvent)
    },
    removeAllFilters() {
      for (let i in this.filters) {
        this.filters[i] = []
      }
      this.temperature = 0
      this.local = false
      this.balcony = false
      this.noPhoto = false
      this.temperature_changed = false
      let filterEvent = {}
      filterEvent.filters = this.filters
      filterEvent.close = false
      this.$store.commit('plant/UPDATE_FILTERS', {
        filters: this.filters,
        balcony: this.balcony,
        temperature: this.temperature,
        local: this.local
      })
      this.$emit('filter-validated', filterEvent)
    }
  },
  watch: {
    temperature() {
      this.temperature_changed = true
    }
  }
}
</script>

<style lang="scss">
.plant-filter {
  .con-vs-slider {
    min-width: 1px !important;
  }

  ul {
    list-style-type: none;
    padding-left: 10px;
  }
}
</style>
