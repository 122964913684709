<template>
  <img v-if="!(interaction === 'none' && !notEmpty)" :src="getImage" alt="interaction">
</template>

<script type="text/javascript">
export default {
  props: {
    vGarden: {
      type: Object,
      default: null
    },
    received_interactions: {
      type: Array,
      default: null
    },
    plantNotInVGarden: {
      type: Boolean,
      default: false
    },
    notEmpty: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    plantScore() {
      if (this.vGarden) {
        let vGardenPlants = this.vGarden.vgarden_plants
        let totalPlants = vGardenPlants.length
        if (totalPlants > 0) {
          var totalPositiveInteractions = 0
          var totalNegativeInteractions = 0
          for (const interactingPlant of this.received_interactions) {
            if (interactingPlant.positive) {
              totalPositiveInteractions += vGardenPlants.filter(plant => plant.garden_plant.plant.scientific_name == interactingPlant.plant_giver.scientific_name).length
            } else {
              totalNegativeInteractions += vGardenPlants.filter(plant => plant.garden_plant.plant.scientific_name == interactingPlant.plant_giver.scientific_name).length
            }
          }
          if (this.plantNotInVGarden) {
            totalPlants++
          }
          return Math.round(100 * (totalPositiveInteractions - totalNegativeInteractions) / totalPlants)
        }
      }
      return null
    },
    getScoreColor() {
      // get color in between danger and primary hex using plant score
      let color1Hex = '#a4131c'
      let color2Hex = '#00564b'
      let color1HexNumbers = color1Hex.substring(1).split(/(?=(?:..)*$)/)
      let color2HexNumbers = color2Hex.substring(1).split(/(?=(?:..)*$)/)
      let result = '#'
      let scoreInPercent = (this.plantScore + 100) / 2
      for (var i = 0; i < 3; i++) {
        result += Math.round((parseInt(color1HexNumbers[i], 16) * (100 - scoreInPercent) + parseInt(color2HexNumbers[i], 16) * scoreInPercent) / 100).toString(16)
      }
      return 'color: ' + result + ' !important;'
    },
    interaction() {
      if (this.plantScore > 60) {
        return "very_good"
      } else if (this.plantScore > 5) {
        return "good"
      } else if (this.plantScore > -5) {
        return "none"
      } else if (this.plantScore > -60) {
        return "bad"
      } else {
        return "very_bad"
      }
    },
    getImage() {
      let img = ""
      try {
        img = require("@/assets/images/icons/other/interaction_" + this.interaction + ".svg")
      } finally {
        //do nothing
      }
      return img
    }
  }
}
</script>
